import React from 'react';
import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { StyleSheet } from "react-native";
import { imageOne, imageTwo, imageFive } from "./assets";
import { TextField, Typography, OutlinedInput, InputAdornment, IconButton, Button } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';


export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }




    render() {

        return (
            <Box style={styles.container}>
                <Grid style={styles.imageContainerBlock}><img src={imageOne.default} alt="imageOne" /></Grid>
                <Grid container style={styles.main}>
                    <Grid item xs={12} style={styles.blockOne}>
                        <Typography style={{ fontSize: "24px", fontWeight: "700", marginBottom: "10px" }}> Sign Up to Shop&Shop</Typography>
                        <FormControl sx={{ m: 1 }} variant="standard" style={{ width: "400px" }}>
                            <Typography >Full Name</Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Your Full Name"
                                data-test-id="txtInputFullName"
                                value={this.state?.fullName}
                                onChange={(e) => this.setfullName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }} variant="standard" style={{ width: "400px" }}>
                            <Typography >{this.labelEmail}</Typography>
                            <TextField
                                data-test-id="txtInputEmail"
                                placeholder="Your Email"
                                value={this?.state?.email}
                                onChange={(e) => this.setEmail(e.target.value)}
                                onBlur={() => this.handleBlur("email")}
                            />
                            {!this.state?.isValid ? <Typography style={{ fontSize: "14px", color: "red" }}>{this.state.isErrorMessage}</Typography> : ""}
                        </FormControl>
                        <FormControl sx={{ m: 1 }} variant="standard" style={{ width: "400px" }}>
                            <Typography >{this.labelPassword}</Typography>
                            <OutlinedInput
                                data-test-id="txtInputPassword"
                                placeholder={"Password"}
                                type={this.state.enablePasswordField ? 'text' : 'password'}
                                value={this.state.password}
                                onChange={(e) => this.setPassword(e.target.value)}
                                onBlur={() => this.handleBlur("encodedData")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            data-test-id="txtInputPasswordToggleBtn"
                                            edge="end"
                                        >
                                            {this.state.enablePasswordField ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {!this.state?.isValidPassword ? <Typography style={{ fontSize: "14px", color: "red" }}>{this.state.isErrorPasswordMessage}</Typography> : ""}
                        </FormControl>
                        <FormControl style={{ display: "flex", justifyContent: "flex-start", width: "100%", paddingLeft: "15px" }}>
                            <Typography>{this.state.isValidPassword ? <img src={imageTwo.default} /> : <img src={imageFive.default} />} At least one capital letter</Typography>
                            <Typography>{this.state.isValidPassword ? <img src={imageTwo.default} /> : <img src={imageFive.default} />} At least one lower case</Typography>
                            <Typography>{this.state.isValidPassword ? <img src={imageTwo.default} /> : <img src={imageFive.default} />} At least one number</Typography>
                            <Typography>{this.state.isValid ? <img src={imageTwo.default} /> : <img src={imageFive.default} />}Invalid email address</Typography>
                        </FormControl>
                        <FormControl sx={{ m: 1 }} variant="standard" style={{ width: "400px", display: "flex", gap: "10px", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <Checkbox
                                data-test-id={"btnRememberMe"}
                                onChange={() =>
                                    this.setRememberMe(!this.state.checkedRememberMe)
                                }
                                checked={this.state.checkedRememberMe}
                            />
                            <Typography style={{ fontSize: "14px" }}>{this.termsAndconditionStatement}</Typography>
                        </FormControl>
                        <FormControl>
                            <Button
                                data-test-id={"btnEmailLogIn"}
                                onClick={()=>this.createAccount()}
                                style={{ width: "400px", backgroundColor: "purple", color: "white" }}
                            >
                                Sign Up
                            </Button>
                        </FormControl>
                        <FormControl style={{ marginTop: "30px" }}>
                            <Typography>Already have and account ? Login </Typography>
                        </FormControl>

                    </Grid>
                </Grid>
            </Box>
        )
    }

}


const styles = StyleSheet.create({
    container: {
        display: "flex",
        height: "100vh",
        alignItems: "center",
        padding: "5px",
        backgroundColor: "#EDEADE",
        justifyContent: "center",
        flexDirection: "column",
    },
    imageContainerBlock: {
        width: "450px",
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px"
    },
    main: {
        height: "630px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "450px",
        backgroundColor: "white",
    },
    blockOne: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "fit-content",
        justifyContent: "center",
        padding: "20px",
    },
    blockTwo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#F4EAFF",
        justifyContent: "center",
        minHeight: "100vh",
    },
    imageBlock: {
        height: "200px",
        width: "200px",
    },
    textBlock: {
        width: "400px",
        fontWeight: "700",
        display: "flex",
        flexWrap: "wrap",
        textAlign: "center",
        justifyContent: "center"
    },

    textBlockSmall: {
        width: "400px",
        display: "flex",
        justifyContent: "center",
    },

});




