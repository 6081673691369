import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  AuthContext,
  Emails,
  GetEmailProps,
  LogInResponse,
  NotificationData,
  NotificationProps,
  Notifications,
  SignUpResponse,
  UserDataProps,
} from "./Service.web";
import Cookies from "js-cookie";
import {
  getStorageData,
  setStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  userData: UserDataProps;
  userEmails: Emails[];
  successText: string;
  message: string;
  description: string;
  isDropdown: boolean;
  isValidConfirmPass: string;
  changeTab: boolean;
  isLoadings: boolean;
  apiErrors: string;
  isVisible: boolean;
  visible: boolean;
  isRefreshing: boolean;
  selectedEmail: string;
  messageError: string;
  descriptionError: string;
  enableField: boolean;
  email: string;
  password: string;
  isValidEmail: string;
  isValidPass: string;
  isLoading: boolean;
  apiError: string;
  isValidEmails: string;
  isValidPas: string;
  userId: string;
  isLogIn: string;
  showConfirmPassword: boolean;
  showPassword: boolean;
  isLoaading: boolean;
  notifications: Notifications[];
  emailError: string;
  fcmToken?: string;
  emails: string;
  passwords: string;
  cPassword: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class DailyScheduleNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType = AuthContext;
  apiSendNotification: string = "";
  apiGetEmail: string = "";
  apiGetAllNotification: string = "";
  apiLoginId: string = "";
  apiSignUpId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      changeTab: false,
      isLoadings: false,
      isVisible: false,
      isLoaading: false,
      isRefreshing: false,
      isValidEmail: "",
      successText: "",
      message: "",
      description: "",
      isDropdown: false,
      selectedEmail: "",
      messageError: "",
      descriptionError: "",
      emailError: "",
      userId: "",
      fcmToken: "",
      emails: "",
      passwords: "",
      cPassword: "",
      isValidEmails: "",
      isValidPas: "",
      isValidConfirmPass: "",
      visible: false,
      isLogIn: "",
      userData: {},
      userEmails: [],
      notifications: [],
      showConfirmPassword: false,
      isValidPass: "",
      isLoading: false,
      apiError: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      email: "",
      password: "",
      apiErrors: "",
      showPassword: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetAllNotificationResponse = (
    responseJson: NotificationProps,
    errorReponse: object
  ) => {
    if (responseJson?.data) {
      this.setState({
        notifications: responseJson?.data,
        isLoaading: false,
        isRefreshing: false,
      });
    }
    if (errorReponse) {
      this.setState({
        notifications: [],
        isLoaading: false,
        isRefreshing: false,
      });
    }
  };
  handleGetEmailResponse = (
    responseJson: GetEmailProps,
    errorReponse: object
  ) => {
    if (responseJson?.data) {
      this.setState({ userEmails: responseJson?.data });
    }
    if (errorReponse) {
      this.setState({ userEmails: [] });
    }
  };
  onOpenDropDown = () => {
    this.setState({ isDropdown: !this.state.isDropdown });
  };
  onRequestClose = () => {
    this.onClose();
    this.closeModal();
  };

  handleSendNotificationResponse = (
    responseJson: NotificationProps,
    errorReponse: object
  ) => {
    if (responseJson.data) {
      this.setState({ isVisible: false });
    }
    if (responseJson?.errors) {
      this.setState({ successText: responseJson?.errors?.push_notification });
    }
    if (errorReponse) {
      this.setState({ successText: "Something went wrong, please try again" });
    }
  };
  sendNotifications = () => {
    if (this.state.selectedEmail == "") {
      this.setState({ emailError: "* Email is required" });
    } else if (this.state.message.trim() == "") {
      this.setState({ messageError: "* Message is required" });
    } else if (this.state.description.trim() == "") {
      this.setState({ descriptionError: "* Description is required" });
    } else {
      this.onClose();
      const object = {
        message: this.state?.message,
        description: this.state?.description,
        id: this.state.userId,
      };
      this.sendNotification(object);
    }
  };
  onClose = () => {
    this.setState({
      message: "",
      description: "",
      isDropdown: false,
      selectedEmail: "",
      messageError: "",
      descriptionError: "",
      emailError: "",
    });
  };
  handleSelection = (email: string, userId: string) => {
    this.setState({
      selectedEmail: email,
      isDropdown: false,
      emailError: "",
      userId: userId,
    });
  };
  handleLogOut = (temp: string) => {
    removeStorageData("UserData");
    removeStorageData("isLogIn");
    removeStorageData("Token");
    this.goToLoginPage();
    if (this.context && (this.context as any).authContext) {
      (this.context as any).authContext.signOut();
    } else if (temp) {
      // Handle temp case if needed
    }
  };

  getEmail = async () => {
    let token = await getStorageData("Token");
    let { role } = this.state.userData;

    let temp = role == "normal_user" ? "business_user" : "normal_user";
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetEmail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmailAPiEndPointJs +
        "?token=" +
        JSON.parse(token) +
        "&type=" +
        temp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSignUpResponse = (
    responseJson: SignUpResponse,
    errorReponse: object
  ) => {
    if (responseJson) {
      if (responseJson?.data) {
        const { device_id, user_type } = responseJson?.data?.attributes;
        let user: UserDataProps = {
          device_id: device_id,
          email: this.state.emails,
          role: user_type,
          id: Number(responseJson?.data?.id),
        };
        setStorageData("UserData", JSON.stringify(user));
        setStorageData("isLogIn", JSON.stringify("true"));
        setStorageData("Token", JSON.stringify(responseJson?.meta?.token));
        this.setState({
          isLogIn: "true",
          isLoadings: false,
          isValidEmails: "",
          emails: "",
          password: "",
          isValidPas: "",
          isValidConfirmPass: "",
          cPassword: "",
        });
        this.goToDashbordPage();
      }
      if (responseJson?.meta?.token) {
        const foundUser = {
          token: JSON.stringify(responseJson?.meta?.token),
        };
        // @ts-expect-error #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
        this.context?.authContext?.signIn(foundUser);
      }

      if (responseJson?.error) {
        this.setState({
          apiErrors: "Something went wrong, please try again",
          isLoadings: false,
          isValidEmails: "",
          isValidPas: "",
          isValidConfirmPass: "",
        });
      }
    }
    if (errorReponse) {
      this.setState({
        apiErrors: "Something went wrong, please try again",
        isLoadings: false,
        isValidEmails: "",
        isValidPas: "",
        isValidConfirmPass: "",
      });
    }
  };
  getAllNotification = async () => {
    this.setState({ isLoaading: true });
    let token = await getStorageData("Token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAllNotification = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotificationAPiEndPointJs + "?token=" + JSON.parse(token)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChanges = (value: string) => {
    if (this.state?.apiErrors != "") {
      this.setState({ emails: value, apiErrors: "" });
    } else {
      this.setState({ emails: value });
    }
  };
  sendNotification = async (data: NotificationData) => {
    let token = await getStorageData("Token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: JSON.parse(token),
    };
    let formData = {
      data: {
        attributes: {
          header: data?.message,
          remarks: data?.description,
          dailyschedule_notificable_id: data?.id,
          dailyschedule_notificable_type: "AccountBlock::Account",
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSendNotification = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendNotificationAPiEndPointJs
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  handleSignUp = () => {
    if (!this.verifyEmail(this.state.emails)) {
      this.setState({ isValidEmails: "Please enter valid email address" });
    } else if (this.state.passwords == "") {
      this.setState({
        isValidPas: "* Password is required field",
        isValidEmails: "",
      });
    } else if (this.state.passwords.length < 4) {
      this.setState({
        isValidPas: "Password must be more than 4 characters",
        isValidEmails: "",
      });
    } else if (this.state.cPassword.length == 0) {
      this.setState({
        isValidConfirmPass: "Confirm password field does not empty",
        isValidPas: "",
      });
    } else if (
      this.state.passwords != this.state.cPassword &&
      this.state.cPassword.length > 0
    ) {
      this.setState({
        isValidConfirmPass: "Confirm password field does not match",
      });
    } else {
      this.SignUp();
    }
  };
  closeModal = () => {
    this.setState({ isVisible: false });
  };
  openModal = () => {
    this.getEmail();
    this.setState({ isVisible: true });
  };
  SignUp = async () => {
    this.setState({ isLoadings: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    let formData = {};
    formData = {
      data: {
        attributes: {
          email: this.state.emails,
          password: this.state.passwords,
          password_confirmation: this.state.cPassword,
          user_type: this.state.changeTab ? "normal_user" : "business_user",
          device_id: this.state.fcmToken,
        },
        type: "email_account",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSignUpId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpApiJs
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  handleLoginResponse = (responseJson: LogInResponse, errorReponse: object) => {
    if (responseJson) {
      if (responseJson?.meta) {
        const { device_id, user_type, token } = responseJson?.meta;
        const foundUser = {
          token: JSON.stringify(token),
        };
        let user: UserDataProps = {
          device_id: device_id,
          email: this.state.email,
          role: user_type,
          id: responseJson?.meta?.id,
        };
        // @ts-expect-error #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
        this.context?.authContext?.signIn(foundUser);
        setStorageData("UserData", JSON.stringify(user));
        setStorageData("isLogIn", JSON.stringify("true"));
        setStorageData("Token", JSON.stringify(token));
        this.setState({ isLogIn: "true" });
        this.goToDashbordPage();
      }
      if (responseJson.errors && responseJson.errors.length > 0) {
        this.clearState();
        this.setState({ apiError: responseJson?.errors[0]?.failed_login });
      }
    }
    if (errorReponse) {
      this.clearState();
      this.setState({ apiError: "Something went wrong, please try again" });
    }
  };

  handleTabUser = () => this.setState({ changeTab: true });
  handleTabBusiness = () => this.setState({ changeTab: false });
  checkEmails = () => {
    if (this.state.emails?.length > 0 && !this.verifyEmail(this.state.emails)) {
      this.setState({ isValidEmails: "Please enter valid email" });
    } else {
      this.setState({ isValidEmails: "" });
    }
  };

  async componentDidMount() {
    let isLogIn: string = "";
    try {
      isLogIn = await getStorageData("isLogIn");
      this.checkLogIn(isLogIn);
    } catch (error) {
      // Put a log to catch an error
    }
  }
  logIn = async () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    let formData = {};
    formData = {
      data: {
        attributes: {
          email: this.state.email,
          password: this.state.password,
          device_id: this.state.fcmToken,
        },
        type: "email_account",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLoginId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logInApiJs
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  checkLogInFirstCondition = (isLogIn: string) => {
    return isLogIn != "" && isLogIn != undefined && isLogIn != null;
  };
  checkLogIn = async (isLogIn: string) => {
    if (this.checkLogInFirstCondition(isLogIn)) {
      this.setState({ isLogIn: JSON.parse(isLogIn) });
      if (this.state.isLogIn != "false") {
        let userData;
        userData = {};
        try {
          userData = await getStorageData("UserData");
          if (userData != "" && userData != undefined && userData != null) {
            this.setState({ userData: JSON.parse(userData) });
          }
          this.getAllNotification();
        } catch (error) {
          // Put a log to catch an error
        }
      } else {
        this.getFcm();
      }
    } else {
      this.getFcm();
      if (
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/signup"
      ) {
        // window.location.href = "/login"
        this.goToLoginPage();
      }
    }
  };
  getFcm = async () => {
    let fcmToken = Cookies.get("Push_NotificationToken");
    if (fcmToken) {
      // user has a device token
      await setStorageData("fcmToken", fcmToken);
    }
    this.setState({ fcmToken: fcmToken });
  };
  goToSignupPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  goToDashbordPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  goToLoginPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  checkEmail = () => {
    if (this.state.email?.length > 0) {
      if (!this.verifyEmail(this.state.email)) {
        this.setState({ isValidEmail: "Please enter valid email" });
      } else {
        this.setState({ isValidEmail: "" });
      }
    }
  };
  verifyEmail = (email: string) => {
    const result = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return result.test(String(email).toLowerCase());
  };

  handleChange = (value: string) => {
    if (this.state?.apiError != "") {
      this.setState({ email: value, apiError: "" });
    } else {
      this.setState({ email: value });
    }
  };
  clearState = () => {
    this.setState({
      isValidEmail: "",
      email: "",
      password: "",
      isValidPass: "",
      isLoading: false,
    });
  };
  handleLogin = () => {
    if (!this.verifyEmail(this.state.email)) {
      this.setState({ isValidEmail: "Please enter valid email" });
    } else if (this.state.password == "") {
      this.setState({
        isValidPass: "* Password is required",
        isValidEmail: "",
      });
    } else if (this.state.password.length < 4) {
      this.setState({
        isValidPass: "Password must be more than four characters",
        isValidEmail: "",
      });
    } else {
      this.logIn();
    }
  };
  // web events
  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleClickShowConfirmPassword = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };
  setPasswordInputLogin = (text: string) => {
    this.setState({ password: text });
  };
  setPasswordInputSignUp = (text: string) => {
    this.setState({ passwords: text });
  };
  setConfirmPasswordInput = (text: string) => {
    this.setState({ cPassword: text });
  };
  setHeaderInput = (text: string) => {
    this.setState({ message: text, messageError: "" });
  };
  setDescriptionInput = (text: string) => {
    this.setState({ description: text, descriptionError: "" });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      // istanbul ignore next
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    } else if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      // istanbul ignore next
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId !== null) {
        // istanbul ignore next
        if (apiRequestCallId === this.apiLoginId) {
          // istanbul ignore next
          this.handleLoginResponse(responseJson, errorReponse);
        } else if (apiRequestCallId === this.apiSignUpId) {
          // istanbul ignore next
          this.handleSignUpResponse(responseJson, errorReponse);
        } else if (apiRequestCallId === this.apiSendNotification) {
          // istanbul ignore next
          this.handleSendNotificationResponse(responseJson, errorReponse);
        } else if (apiRequestCallId === this.apiGetAllNotification) {
          // istanbul ignore next
          this.handleGetAllNotificationResponse(responseJson, errorReponse);
        } else if (apiRequestCallId === this.apiGetEmail) {
          // istanbul ignore next
          this.handleGetEmailResponse(responseJson, errorReponse);
        }
      }
    }
  }
}
// Customizable Area End
